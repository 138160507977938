@import 'src/scss/core.module.scss';

.container {
  max-width: 1080px;
  margin: 26px auto 0;
  height: calc(100% - 26px);
  padding: 0 10px;

  .title {
    margin: 24px 0;
    font-weight: bold;
    font-size: 23px;
    line-height: 32px;
    display: inline-block;
  }

  .breadcrumbs {
    font-size: 14px;
    font-weight: 600;
    line-height: 26px;
  }

  .table-box {
    padding-left: 270px;

    .table-title {
      margin: 14px 0;
      font-size: 19px;
      font-weight: 700;
      line-height: 24px;
    }
  }

  .fab {
    position: fixed;
    bottom: 40px;
    right: 60px;
    z-index: 1;
    color: $White;
    text-transform: capitalize;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }
}

.row-title {
  font-size: 14px;
  font-weight: 700;
  color: $Black;
}
