@import 'src/scss/core.module.scss';

.fc {
  .fc-button-primary {
    color: $Blue;
    padding: 4px 10px;
    font-size: 14px;
    border-color: $Blue;
    background-color: $Background;
  }

  .fc-button-primary:hover {
    border-color: $Blue;
    background-color: $HoverBlue;
  }

  .fc-button-primary:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .fc-button-primary:not(:disabled).fc-button-active, .fc-button-primary:disabled {
    border-color: $Blue;
    background-color: $Blue;
  }

  .fc-toolbar-title {
    font-size: 14px;
  }

  .fc-day-past {
    background-color: $Background;

    .fc-event {
      opacity: 0.6;
      pointer-events: none;
    }
  }
}
