@import '../../../scss/core.module';

.content-uploader {
  margin-top: 30px;

  .card-container {
    display: flex;
  }

  .content-card {
    width: 93px;
    height: 106px;
    border-radius: 2px;
    flex-direction: column;
    border: 1px solid $SoftGray;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $Blue;
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }

    &.disabled {
      color: $CrGray;
      background-color: $Background;
    }

    .title {
      font-size: 14px;
    }
  }

  .label {
    margin-top: 0;
    color: $DarkCrGray;
    font-size: 16px;
    font-weight: 400;
  }

  .content {
    margin-top: 30px;
  }

  .text {
    width: 100%;
  }

  .error {
    color: $RoseRed;
    font-size: 12px;
    font-weight: 400;
    margin: 5px 14px;
  }
}
