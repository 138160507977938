@import 'src/scss/core.module';

.container {
  width: 420px;
  height: 490px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .title {
    font-weight: 700;
    font-size: 23px;
    line-height: 32px;
    margin: 0 0 23px;

    .highlighted {
      color: $Blue;
    }
  }
}

.notification {
  width: 90%;
}

.paper {
  width: 100%;
  height: 280px;
  max-height: 280px;
}

.listbox {
  height: 264px;
}