@import 'src/scss/core.module.scss';

$RowHeight: 76px;
$HeaderHeight: 40px;

.tr {
  height: $RowHeight;
  display: flex;
  align-items: center;

  &.dragging {
    display: flex;
    align-items: center;
    background-color: $White;
    box-shadow: 0 2px 8px $DragHover, 0 8px 16px $DragHover;
    border-radius: 2px;
  }
}

.container {
  display: block;
  max-width: 100%;
  height: 100%;

  .wrapper {
    display: block;
    max-width: 100%;
    height: 100%;
  }

  .empty-state {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .table {
    border-spacing: 0;
    width: 100%;
    height: 100%;

    .tr {
      border-top: 1px solid $Background;
    }

    .header {
      height: $HeaderHeight;
      border-top: none;
    }
  }
}

.row-dragging {
  display: flex;
  align-items: center;

  .icon {
    margin-right: 6px;
    color: $DarkCrGray;
  }
}

.th,
.td {
  margin-right: 26px;
  color: $DarkCrGray;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.drag-container {
  height: $RowHeight;
}

.expanded,
.selected {
  background-color: $ExpandedBackground;
}

.combine-hover {
  background-color: $LightBlue;
}
