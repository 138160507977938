@import 'src/scss/core.module.scss';

.account-page {
  height: 100%;
  padding: 0 10px;
  max-width: 720px;
  width: 720px;
  margin: 0 auto;

  .back-button {
    height: 36px;
    width: 36px;
    margin-right: 8px;
    color: $Black;

    &:hover {
      background: $SoftGray;
    }
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 32px 0;
    justify-content: space-between;

    .title {
      font-weight: bold;
      font-size: 23px;
      color: $Black;
      margin: 0 32px 0 0;
    }

    .subtitle {
      color: $DarkCrGray;
      font-size: 14px;
      font-weight: 400;
    }

    .date {
      font-weight: 600;
    }

    &-info {
      display: flex;
    }
  }

  .info-value {
    color: $Black;
    font-weight: 600;
  }

  .email {
    color: $Blue;
    font-weight: bold;
    width: 100%;
  }

  .switch {
    white-space: nowrap;
    padding-right: 10px;
  }

  .password-row {
    border: transparent;
  }
}


