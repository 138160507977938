@import 'src/scss/core.module';

.overview {
  color: $Black;

  .title {
    font-weight: 600;
    font-size: 26px;
    margin: 20px 0 24px 0;
  }

  .container {
    background: $BlueReport;
    margin-bottom: 16px;
    padding: 16px;

    .label {
      margin: 0 0 16px 0;
      font-size: 18px;
      font-weight: 600;
    }

    .list {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      gap: 30px;

      .li {
        font-size: 14px;
        width: 264px;
        margin-bottom: 4px;
        font-weight: 600;
        counter-increment: li;
      }

      .li::before {
        color: $CrGray;
        margin-right: 16px;
        content: counter(li) ".";
      }
    }
  }
}

@media print {
  .overview {
    .title {
      font-size: 24px;
    }

    .container {
      .label {
        font-size: 16px;
      }
    }
  }
}
