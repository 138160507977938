@import 'src/scss/core.module.scss';

.card-container {
  position: relative;
  min-width: 250px;
  width: 100%;

  .card {
    width: 100%;
    border: 1px solid $Background;
    box-sizing: border-box;
    box-shadow: none;
    border-radius: 2px;

    &.small {
      height: 208px;
    }

    &.big {
      height: 280px;
      max-height: 280px;
    }

    .content {
      display: flex;
      padding: 4px 16px;
      height: 36px;
      align-items: center;
    }

    .title-button {
      padding: 0;
      min-width: auto;
      color: $Black;

      &:hover {
        background-color: transparent;
        color: $Blue;
      }
    }

    .preview-button-wrap {
      position: absolute;
      top: 8px;
      right: 8px;

      .preview-button {
        width: 90px;
        height: 25px;
        padding: 6px;
        font-weight: 600;
        font-size: 12px;
        border-radius: 2px;

        &:not(:disabled) {
          color: $DarkCrGray;
          background: $White;
        }

        &:hover {
          background: $LightBlue;
        }
      }
    }

    .folder-icon {
      height: 34px;
      width: 34px;
      margin-right: 10px;
    }

    .card-actions {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 4px 16px;

      * {
        margin: 0;
        height: 28px;
        font-size: 14px;
      }

      > :first-child {
        margin-bottom: 8px;
      }
    }
  }
}
