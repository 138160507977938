@import 'src/scss/core.module.scss';

.file-uploader {
  display: flex;
  align-items: center;

  .image {
    height: 64px;
    margin-right: 16px;
  }

  .button {
    margin-right: 16px;
  }
}

.label {
  margin-top: 0;
  color: $DarkCrGray;
  font-size: 16px;
  font-weight: 400;
}


.name {
  font-size: 14px;
  font-weight: 600;
  margin: 5px;
  color: $DarkCrGray;
  display: inline-block;
}
