@import 'src/scss/core.module.scss';

$RowHeight: 76px;

.container {
  height: 100%;
}

.chip {
  text-transform: capitalize;
  background-color: $LightBlue;
}

.expand-icon {
  color: $Black;

  &-active {
    transform: rotate(-180deg);
  }
}

.title {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: $Black;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  text-decoration: none;
}

.learner-task-library-sub-rows {
  position: relative;
}

.sub-row {
  width: 100%;
  height: $RowHeight;
  display: flex;
  align-items: center;
  background-color: $ExpandedBackground;

  &.dragging {
    box-shadow: 0 2px 8px $DragHover, 0 8px 16px $DragHover;
    border-radius: 2px;
  }

  &.combine-hover {
    background-color: $LightBlue;
  }
}

.sub-row-icon-bar {
  margin-left: 20px;
}

.td {
  margin-right: 26px;
  color: $DarkCrGray;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sub-row-dragging {
  display: flex;
  align-items: center;

  .combine-icon {
    margin-right: 6px;
    color: $RoseRed;
  }
}
