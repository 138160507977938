@import 'src/scss/core.module.scss';

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  background-color: $White;
  border: 1px solid $SoftGray;
  padding: 32px 25px;
  min-width: 400px;
  box-sizing: border-box;

  .title {
    color: $Black;
    font-size: 23px;
    font-weight: bold;
    margin: 0 0 32px 0;
  }

  .content {
    overflow-y: auto;
    padding: 0 5px;
    max-height: calc(100vh - 175px);
  }
}
