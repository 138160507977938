@import 'src/scss/core.module.scss';

.container {
  height: calc(100% - 56px);
  overflow-y: visible;

  .table {
    min-width: 880px;
  }

  .search {
    width: 100%;
    margin-bottom: 16px;
  }

  .icon {
    color: $SoftGray;
  }
}

.chip {
  text-transform: capitalize;
  background-color: $LightBlue;
}

.expand-icon {
  color: $Black;

  &-active {
    transform: rotate(-180deg);
  }
}

.title {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: $Black;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  text-decoration: none;
}

.sub-row-icon-bar {
  margin-left: 20px;
}

.actions {
  text-align: right;
}
