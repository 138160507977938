@import 'src/scss/core.module';

.expanded-activity-table-cell {
  display: flex;
  flex-direction: column;
  color: $Black;
  font-size: 16px;

  .label {
    font-weight: 600;
  }

  .value {
    font-weight: 400;
  }
}
