@import 'src/scss/core.module.scss';

.icon-cell {
  flex: 1;
  padding-left: 10px;
  overflow: hidden;

  .image {
    position: relative;

    .icon {
      position: absolute;
      bottom: -6px;
      right: -4px;
    }
  }

  .title {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: $Black;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .button {
    min-width: 0;
    font-size: 14px;
    line-height: 20px;
    color: $Black;
    padding: 0;
    text-align: left;
    white-space: nowrap;
    width: 100%;
    margin: 0;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;

    &:hover {
      color: $HoverBlue;
      background: none;
    }
  }

  .nav-link {
    text-decoration: none;

    &:hover {
      * {
        color: $HoverBlue;
      }
    }
  }

  .drag-icon {
    margin-top: 8px;
    height: 20px;
    width: 20px;
    color: $CrGray;

    &.disabled {
      color: $SoftGray;
    }
  }

  .info {
    overflow: hidden;
  }

  .subtitle {
    color: $Black;
    font-size: 12px;
    font-weight: 400;
  }

  .preview-button {
    font-size: 12px;
    color: $CrGray;

    &:hover,
    &:focus,
    &:active {
      color: $DarkCrGray;
      background-color: transparent;
    }
  }
}
