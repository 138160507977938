@import 'src/scss/core.module.scss';

.container {
  max-width: 1080px;
  margin: 26px auto 0;
  height: calc(100% - 26px);
  padding: 0 10px;
  position: relative;

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 26px;

    .title-container {
      display: flex;

      .title {
        margin: 0 24px 0 0;
        font-weight: bold;
        font-size: 23px;
        line-height: 32px;
        display: inline-block;
      }
    }
  }

  .table {
    margin-top: 24px;
    height: calc(100% - 122px);
    min-width: 870px;
  }

  .chip {
    text-transform: capitalize;
  }

  .primary-chip {
    background-color: $LightBlue;
  }
}
