@import 'src/scss/core.module.scss';

.action {
  text-align: right;
  font-size: 16px;

  .snack-bar {
    width: 62%;
    white-space: normal;
  }
}

.menu {
  .delete-button {
    color: $RoseRed;
  }
}
