@import 'src/scss/core.module.scss';

.task-card-grid {
  width: 100%;
  padding: 30px 0;

  .title {
    margin: 0 0 16px 24px;
  }

  .content {
    height: calc(100% - 55px);
    width: 100%;

    .card-row {
      padding: 0 24px 0 0;
      margin: 0 0 24px 0;
    }
  }
}

