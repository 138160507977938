@import 'src/scss/core.module.scss';

.repertoire-accordion {
  width: 150px;
  height: 100%;

  .accordion {
    width: 150px;
    margin: 0;
    box-shadow: none;
    border: none;
    height: 100%;
    max-height: 100%;
    border-right: 1px solid $LightBlueGray;

    .accordion-summary {
      margin: 0;
      border: none;
      border-bottom: 1px solid $LightBlue;
      color: $DarkCrGray;
      background-color: $LightBlueGray;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
    }

    .accordion-icon {
      color: $DarkCrGray;
    }

    .accordion-details {
      padding: 0;
      background-color: $LightBlueGray;
    }

    .accordion-list {
      padding: 0;
    }

    .accordion-list-item {
      color: $Blue;
      height: auto;

      * {
        font-weight: 600;
        font-size: 14px;
      }

      &.active {
        color: $Black;
        background-color: $LightBlue;
      }
    }
  }
}

.accordion-root {
  height: 100%;

  >:nth-child(2) {
    max-height: calc(100% - 64px);
    overflow-y: auto;
  }
}
