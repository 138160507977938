@import 'src/scss/core.module.scss';

.confirmation-screen {
  max-width: 420px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .content {
    color: $Black;
    text-align: center;
    margin: 32px 0 40px;
  }

  .image {
    max-width: 96px;
    max-height: 96px;
  }
}
