@import 'src/scss/core.module';

.password-warning-banner {
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 14px 16px;
  max-width: 540px;
  font-size: 12px;
  font-weight: normal;
  color: $Black;
  list-style-type: disc;
  margin-bottom: 10px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #ED6C02;

  .label {
    font-weight: 700;
    margin-left: 24px;
  }

  .list {
    list-style-type: disc;
  }

  .warning-icon {
    color: $DarkCrGold;
  }
}

