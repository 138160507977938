@import 'src/scss/core.module.scss';

.user-task-details-page {
  width: 100%;

  .navigation {
    height: 68px;
    display: flex;
    align-items: center;
    padding-left: 30px;
    box-shadow: 0 1px 0 $Background;
    font-weight: 700;
    font-size: 16px;

    .icon {
      height: 36px;
      width: 36px;
      margin-right: 8px;
      color: $Black;

      &:hover {
        background: $SoftGray;
      }
    }
  }

  .container {
    height: calc(100% - 94px);
    padding: 16px 30px 0;
    overflow-y: auto;

    .content {
      margin: 16px 30px;
      max-width: 720px;
      padding-bottom: 24px;
      border-bottom: 1px solid $Background;

      .main-title {
        font-size: 23px;
        font-weight: bold;
        margin-top: 0;
        margin-bottom: 16px;
      }

      .title {
        margin: 4px 0 8px;
        font-weight: 700;
        font-size: 19px;
      }

      .image {
        height: 180px;
        width: 180px;
      }

      .primary-chip {
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 16px;
        background-color: $LightBlue;
      }

      .description {
        font-size: 14px;
        margin-bottom: 8px;

        &:empty {
          height: 19px;
        }
      }

      .avatar-container {
        display: flex;
        font-size: 14px;
        align-items: center;

        .avatar-title {
          min-width: 80px;
          margin-right: 4px;
        }
      }

      .actions {
        display: flex;
        flex-wrap: wrap;

        button {
          margin-top: 8px;
        }

        .button {
          margin-right: 16px;
        }

        .delete-button {
          margin-left: auto;
        }
      }
    }

    .step-list {
      margin: 0 30px;
    }
  }
}
