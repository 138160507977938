@import 'src/scss/core.module.scss';

.table {
  height: calc(100% - 66px);
  min-width: 670px;

  .chip {
    text-transform: capitalize;

    &.primary {
      background-color: $LightBlue;
    }
  }
}

.fabLink {
  position: absolute;
  bottom: 40px;
  right: 60px;
  z-index: 57;
  text-decoration: none;
}

.fab {
  color: $White;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}
