@import 'src/scss/core.module';

.overview {
  display: flex;
  gap: 60px;
  flex-direction: row;
  color: $Black;
  margin: 42px 0 22px;

  .section {
    width: 50%;
  }
}

.title {
  font-weight: 600;
  font-size: 26px;
  margin: 0 0 16px 0;
}

.header {
  margin-top: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  .title {
    margin: 0;
  }

  .subtitle {
    color: $DarkCrGray;
    font-weight: 600;
    font-size: 14px;
  }
}

@media print {
  .overview {
    margin-top: 42px;
    margin-bottom: 0;
    flex-direction: column;

    .section {
      width: 100%;

      .title {
        margin-top: 0;
      }
    }
  }
}
