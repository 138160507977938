@import 'src/scss/core.module.scss';

.create-organization-details {
  display: flex;
  flex-direction: column;

  .form {
    margin: 16px 0 8px;
  }
}
