@import 'src/scss/core.module.scss';

$nav-header-height: 64px;

.layout {
  height: calc(100% - 64px);
  padding-top: $nav-header-height;

  .error-wrapper {
    min-height: calc(100vh - #{$nav-header-height});
  }
}

@media print {
  .layout {
    padding-top: 0;
  }
}

