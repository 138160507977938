@import 'src/scss/core.module.scss';

.container {
  max-width: 1080px;
  margin: 26px auto 0;
  height: calc(100% - 26px);
  padding: 0 10px;
  position: relative;

  .filters {
    margin-bottom: 18px;
    height: 44px;
  }

  .header {
    font-weight: bold;
    font-size: 23px;
    line-height: 32px;
    margin: 0;
  }

  .select {
    background-color: $SoftGray;
    width: 100%
  }

  .search {
    width: 100%
  }

  .icon {
    color: $SoftGray
  }

  .fabLink {
    position: absolute;
    bottom: 40px;
    right: 34px;
    z-index: 57;
    text-decoration: none;
  }

  .fab {
    color: $White;
    text-transform: capitalize;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }

  .table {
    height: calc(100% - 66px);
    min-width: 850px;

    .chip {
      text-transform: capitalize;

      &.primary {
        background-color: $LightBlue;
      }
    }
  }
}
