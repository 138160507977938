@import 'src/scss/core.module.scss';

.empty-state-box {
  height: calc(100% - 240px);
}

.list-item {
  display: flex;
  align-items: center;

  .copy-icon {
    fill: $DarkCrGray;
    padding-right: 10px;
  }
}
