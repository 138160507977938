@import 'src/scss/core.module.scss';

.task-details {
  width: 100%;
  padding: 24px 30px;
  overflow-y: auto;
  box-sizing: border-box;

  .content {
    width: calc(100% + 24px);

    .task-image {
      width: 100%;
      height: 253px;
      border-radius: 2px;
    }

    .content-text {
      width: 100%;
      padding: 15px 0;

      .chip {
        font-weight: 600;
        background-color: $LightBlue;
      }

      .description-title {
        color: $DarkCrGray;
        font-weight: bold;
        font-size: 16px;
        margin: 8px 0;
      }

      .description {
        font-size: 16px;
      }

      .actions {
        display: flex;
        flex-wrap: wrap;
        padding: 16px 0;

        > :not(:first-child) {
          margin: 0 0 8px 8px;
        }

        > :first-child {
          margin-bottom: 8px;
        }

        > :nth-child(3) {
          margin-left: auto;
        }

        button {
          height: 28px;
        }

        .preview-button {
          width: 90px;
          padding: 6px;
          font-weight: 600;
          font-size: 12px;
          border-radius: 2px;
        }
      }
    }
  }
}
