@import 'src/scss/core.module.scss';

.stepper {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 80px);
  margin-top: 16px;

  .content {
    width: 100%;
    max-width: 440px;
    margin: 16px auto;
    padding: 0 16px 10px;
    overflow-y: auto;
  }

  .header {
    display: flex;
    align-items: center;
    margin: 32px 0;

    .icon {
      margin-right: 8px;
      color: $Black;

      &:hover {
        background: $SoftGray;
      }
    }

    .label {
      margin: 0;
      color: $Black;
      font-size: 23px;
      font-weight: 700;
      line-height: 40px;
    }
  }

  .footer {
    margin-top: 32px;

  }
}
