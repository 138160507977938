@import 'src/scss/core.module';

.reduction-tab {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    margin: 0;
    font-weight: 600;
    font-size: 14px;
  }
}

.tooltip {
  border-radius: 2px;
  font-weight: 600
}
