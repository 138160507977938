@import 'src/scss/core.module';

.navigation {
  height: 68px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  box-shadow: 0 1px 0 $Background;
  font-weight: 700;
  font-size: 16px;

  .icon {
    height: 36px;
    width: 36px;
    margin-right: 8px;
    color: $Black;

    &:hover {
      background: $SoftGray;
    }
  }
}

.task-details-page {
  height: calc(100% - 94px);
  padding: 16px 30px 0;
  overflow-y: auto;

  .content {
    margin: 16px auto 0;
    max-width: 720px;
    padding-bottom: 24px;
    border-bottom: 1px solid $Background;

    .main-title {
      font-size: 23px;
      font-weight: bold;
      margin-top: 0;
      margin-bottom: 16px;
    }

    .title {
      margin: 4px 0 8px;
      font-weight: 700;
      font-size: 19px;
    }

    .button {
      margin-right: 16px;
    }

    .image {
      height: 180px;
      width: 180px;
    }

    .primary-chip {
      font-size: 12px;
      font-weight: 600;
      background-color: $LightBlue;
    }

    .description {
      font-size: 14px;
      margin: 20px 0 30px;

      &:empty {
        height: 19px;
      }
    }

    .folder-container {
      display: flex;
      align-items: center;

      &:empty {
        height: 17px;
      }

      .image {
        width: 12px;
        height: 12px;
        margin-right: 8px;
      }

      .folder-title {
        font-size: 12px;
        font-weight: 600;
      }
    }

    .delete-container {
      align-self: end;
    }
  }

  .step-list {
    max-width: 720px;
    margin: 0 auto;
  }
}
