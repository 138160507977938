@import 'src/scss/core.module.scss';

.shared-library-action-menu {
  .delete-button {
    color: $RoseRed;
  }
}

.more-icon {
  color: $Blue;
}

.message {
  width: 90%;
}
