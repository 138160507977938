@import 'src/scss/core.module.scss';

.container {
  width: 480px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .autocomplete {
    margin-top: 5px;
  }
}

.paper {
  width: 480px;

  &:first-child {
    width: 100%;
    height: 280px;
    max-height: 280px;
  }
}

.listbox {
  height: 264px;
}
