@import 'src/scss/core.module.scss';

.association-section {
  width: 100%;

  .label {
    color: $DarkCrGray;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    padding-bottom: 10px;
    border-bottom: 1px solid $Background;
  }

  .content {
    margin: 10px 0;
  }
}
