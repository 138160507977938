@import 'src/scss/core.module.scss';

.info-row {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 24px 0;
  border-bottom: 1px solid $SoftGray;

  .label {
    color: $DarkCrGray;
    font-size: 16px;
  }

  .value {
    display: flex;
    padding-left: 20px;
    align-items: center;
  }
}
