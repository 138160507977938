@import 'src/scss/core.module.scss';

.organization-details-page {
  display: flex;
  height: 100%;

  .drawer {
    width: 240px;
    flex-shrink: 0;
  }

  .image {
    height: 64px;
    width: 64px;
    margin-right: 16px;
  }

  .info {
    display: flex;
    align-items: center;
    padding: 32px 16px;

    .label {
      color: $Black;
      font-size: 16px;
      font-weight: 700;
      margin: 0 0 4px 0;
    }

    .chip {
      background-color: $White;
      max-width: 124px;
    }

    .icon {
      min-width: 44px;
    }
  }
}
