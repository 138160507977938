.event-form {
  display: flex;
  flex-direction: column;
  width: 480px;

  .footer {
    margin-top: 32px;
  }

  .select {
    margin: 32px 0 0;
  }

  .input {
    margin: 5px 0 0;
  }

  .switch {
    margin: 32px 0 0;
  }
}
