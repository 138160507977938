.container {
  width: 480px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .autocomplete {
    margin-top: 5px;
  }

  .empty-box {
    height: 390px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .checkbox {
    margin-right: 8px;
  }

  .footer {
    margin-top: 5px;
  }
}

.paper {
  width: 380px;

  &:first-child {
    width: 100%;
    height: 380px;
    max-height: 380px;
  }

  .listbox {
    height: 364px;
    max-height: 364px;
  }
}
