@import 'src/scss/core.module.scss';

.folder-cell {
  .title {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: $Black;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    text-decoration: none;
  }

  .chip {
    background-color: $LightBlue;
    margin: 2px 14px 0 0;
  }

  .subtitle {
    color: $Black;
    font-size: 12px;
    font-weight: 400;
  }

  .image {
    position: relative;
    margin-left: 8px;

    .icon {
      position: absolute;
      bottom: 0;
      right: -2px;
    }
  }

  .info-wrap,
  .info {
    overflow: hidden;
  }

  .button {
    width: 100%;
    padding: 0;
    border: 0;
    text-align: left;
    min-width: 10px;
    background: transparent;

    &:hover {
      color: $Blue;
      background: transparent;
    }
  }

  .back-button {
    width: 42px;
    height: 42px;
  }

  .drag-icon {
    height: 20px;
    width: 20px;
    margin: 0 6px 0 2px;
    color: $CrGray;
  }
}
