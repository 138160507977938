@import 'src/scss/core.module.scss';

.table-action-bar {
  >:last-child {
    margin-left: auto;
  }

  .select {
    background-color: $SoftGray;
    width: 100%;
  }

  .search {
    width: 100%
  }

  .icon {
    color: $SoftGray
  }

}
