.change-password-form {
  display: flex;
  flex-direction: column;

  .footer {
    margin-top: 32px;
  }
}


