@import 'src/scss/core.module.scss';

.shared-library {
  min-width: 300px;
  padding: 16px 60px 60px 0;
  height: calc(100% - 210px);

  .header {
    margin: 0 0 8px 0;
    font-weight: 700;
    font-size: 19px;
  }

  .avail-button {
    width: 175px;
    margin-bottom: 20px;
  }

  .search {
    margin-bottom: 16px;

    .icon {
      color: $SoftGray;
    }
  }
}
