@import 'src/scss/core.module';

.organization-summary-section {
  color: $Black;
  height: 174px;

  .container {
    display: flex;
    flex-direction: column;
  }

  .row {
    width: 100%;
    padding: 8px 0;
    border-bottom: 1px solid $Background;
    display: flex;
    flex-direction: row;
    align-items: center;

    .title {
      margin: 0;
      width: 50%;
      font-size: 12px;
      font-weight: 700;
    }

    .value {
      width: 50%;
      font-size: 12px;
      font-weight: 500;
    }
  }
}

@media print {
  .overview {
    .title {
      font-size: 24px;
    }
  }
}
