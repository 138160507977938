@import 'src/scss/core.module.scss';

.shared-library-list-item {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
  background-color: $Background;

  &.dragging {
    box-shadow: 0 2px 8px $DragHover, 0 8px 16px $DragHover;
  }

  &.active-folder {
    justify-content: start;
    background-color: $White;
  }

  .back-button {
    width: 42px;
    height: 42px;
  }

  .icon-cell-box {
    position: relative;
    width: 0;
    flex: 1;
  }
}
