@import 'src/scss/core.module.scss';

.modal-header {
  display: flex;
  justify-content: flex-start;
  padding: 30px;
  box-shadow: 0 1px 0 $Background;

  .title {
    color: $Black;
    font-size: 23px;
    font-weight: bold;
    margin: 0;
    min-width: 150px;
  }

  .search {
    width: 100%;
    max-width: 750px;
    margin: 0 50px;

    ::placeholder {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: $DarkCrGray;
    }
  }

  .icon {
    color: $SoftGray
  }

  .close-button {
    margin-left: auto;
  }
}
