@import 'src/scss/core.module.scss';

.shared-folder-details {
  height: 100%;
  width: 100%;
  overflow-y: auto;

  .task-title {
    margin: 8px 30px 16px;
    font-weight: bold;
    font-size: 19px;
    line-height: 24px;
  }

  .card-container {
    padding: 0 30px 30px;
  }

  .empty-state {
    margin: 30px 0;
  }
}


