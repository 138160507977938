@import 'src/scss/core.module';

.title {
  font-weight: 700;
  font-size: 23px;
  color: $Black;
  margin-top: 0;
}

.tasks-container {
  overflow-y: scroll;
  word-break: break-all;
  height: calc(100vh - 344px);

  .task-card {
    margin-bottom: 4px;
  }
}
