@import 'src/scss/core.module.scss';

.label {
  margin: 0;
  color: $DarkCrGray;
  font-weight: 400;
  font-size: 16px;
}

.row {
  margin-bottom: 20px;
}

.banner {
  margin-top: 24px;
  border-radius: 4px;
  padding: 8px 16px;
  background-color: $LightYellow;
}
