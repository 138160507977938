@import 'src/scss/core.module';

.report-task {
  .overview-section {
    background-color: $ExpandedBackground;
    padding: 16px;
    margin-bottom: 40px;

    .steps {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      page-break-before: avoid;
    }

    .chip {
      margin-left: 8px;
      font-weight: 600;
      background-color: $LightBlue;
    }

    .subtitle {
      color: $DarkCrGray;
      font-size: 14px;
      font-weight: 400;
      margin-left: 16px;
    }

    .date {
      font-weight: 600;
    }
  }

  .title {
    font-size: 18px;
    color: $Black;
    margin-bottom: 16px;
    margin-top: 0;
  }

  .section {
    margin-bottom: 40px;
  }

  .reduction-tab {
    flex-direction: row;
  }
}

@media print {
  .report-task {
    .print {
      page-break-inside: avoid;
      page-break-after: auto;
    }
  }
}
