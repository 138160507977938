@import 'src/scss/core.module.scss';

.container {
  margin-bottom: 24px;

  .select {
    background-color: $SoftGray;
    width: 100%;
  }

  .search {
    flex: 1;
    margin-right: 32px;

    .icon {
      color: $SoftGray;
    }
  }

  .label {
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
  }
}
