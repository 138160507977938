@import 'src/scss/core.module.scss';

$HorizontalPadding: 36px;

.container {
  max-width: calc(100vw - 312px);
  width: 100%;
  padding: 20px $HorizontalPadding 0;
  position: relative;

  .filters {
    margin-bottom: 18px;
  }

  .tab-panel {
    padding: 0;
    height: calc(100% - 64px);
    width: 100%;
    overflow-x: scroll;
  }
}
