@import 'src/scss/core.module';

.organization-overview-section {
  color: $Black;
  height: 170px;

  .container {
    display: flex;
    flex-direction: column;

    .section {
      gap: 10px;
      display: flex;
      justify-content: space-between;

      &:first-child {
        margin-bottom: 10px;
      }
    }
  }

  .card {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: $BlueReport;
    padding: 12px;
    align-items: center;
    justify-content: center;
    border-radius: 2px;

    .value {
      margin: 0;
      font-size: 22px;
      font-weight: 600;
    }

    .title {
      font-size: 12px;
      font-weight: 400;
      text-align: center;
    }
  }
}

@media print {
  .overview {
    .title {
      font-size: 24px;
    }
  }
}
