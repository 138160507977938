@import 'src/scss/core.module.scss';


.label-block {
  padding-bottom: 20px;
}

.label {
  display: block;
  color: $DarkCrGray;
}

.info-value {
  color: $Black;
  font-weight: 600;
}

.email {
  color: $Blue;
  font-weight: bold;
  margin-left: 16px;
}

.create-admin-form {
  display: flex;
  flex-direction: column;
  max-width: 540px;

  .footer {
    margin-top: 32px;
  }

  .select {
    margin: 16px 0 8px;
  }

  .tabs {
    .tab {
      flex: 1;
      padding: 12px 0px;
      font-size: 16px;
      font-weight: 700;
      text-transform: none;
    }
  }

  .tab-panel {
  width: 100%;
  height: 320px;
  }

  .tab-panel-root {
  padding: 0px 0;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .autocomplete {
    margin-top: 5px;
  }

  .empty-box {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .checkbox {
    margin-right: 8px;
  }

  .footer {
    margin-top: 5px;
  }
}

.paper {
  &:first-child {
    width: 100%;
    height: 250px;
    max-height: 250px;
  }

  .listbox {
    height: 230px;
    max-height: 230px;
  }
}

.create-organization-admin {
  display: flex;
  flex-direction: column;
}

.icon {
  color: $SoftGray;
}