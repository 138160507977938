@import 'src/scss/core.module.scss';

.task-back-header {
  height: 52px;
  display: flex;
  align-items: center;
  margin: 0 0 24px 0;

  .icon {
    margin-right: 8px;
    color: $Black;

    &:hover {
      background: $SoftGray;
    }
  }

  .folder-icon {
    width: 32px;
    height: 24px;
    margin-right: 8px;
  }

  .label {
    margin: 0;
    color: $Black;
    font-size: 19px;
    font-weight: 700;
    line-height: 40px;
  }
}
