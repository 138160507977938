@import 'src/scss/core.module.scss';

.steps-list {
  padding: 16px 0;
  max-width: 720px;

  .title {
    font-size: 23px;
    font-weight: bold;
    margin: 0;
  }

  .add-button {
    width: 100%;
    margin: 24px 0;
    border: 1px dashed $Blue;
    box-sizing: border-box;
    border-radius: 2px;
  }
}
