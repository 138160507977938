.create-organization-page {
  max-width: 720px;
  margin: 0 auto;

  .confirmation-banner {
    margin: 104px auto;

    .info-message {
      font-size: 23px;
      font-weight: 700;
      max-width: 360px;
    }
  }
}
