@import 'src/scss/core.module';

.mui-table {
  .header {
    padding: 14px;
    font-size: 12px;
    font-weight: 600;
    background-color: $LightBlueGray;
  }

  .row {
    padding: 12px;
    font-size: 12px;
  }
}
