@import 'src/scss/core.module.scss';

.step-preview {
  color: $Black;
  max-width: 360px;
  position: relative;

  .title {
    max-width: 180px;
    text-align: center;
    font-weight: 600;
    min-height: 40px;
    margin: auto;
  }

  .step-content {
    .timer {
      position: absolute;
      top: 0;
      left: 0;

      .timer-title {
        color: $DarkCrGray;
      }

      .timer-count {
        font-weight: 600;
      }
    }

    .step-title {
      font-size: 19px;
      line-height: 24px;
      font-weight: bold;
      margin: 16px 0;
    }

    .content-wrap {
      height: 360px;
      width: 360px;
      overflow-y: auto;

      .content-text {
        white-space: pre-line;
      }

      .content-audio {
        height: 100%;
        flex-direction: column;
        align-items: center;

        .microphone-img {
          flex: 1;
          background-color: $LightBlue;
        }

        .audio {
          width: 100%;
        }
      }

      .image {
        display: block;
        width: 100%;
        height: 100%;
        min-height: 0;
        object-fit: contain;
        flex: 1;
      }

      .video {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .actions {
    margin: 24px 0;

    .step-action-btn {
      flex: 1;
      flex-direction: column;

      &.replay-btn {
        margin: 0 12px;
        max-width: 100px;
        height: 60px;
      }

      .step-action-btn-icon {
        font-size: 20px;
      }
    }
  }

  .stepper {
    margin-bottom: 32px;

    .linear-progress {
      flex: 1;
      margin-right: 2px;
      height: 12px;

      &.first-linear-progress {
        border-radius: 10px 0px 0px 10px;
      }

      &.last-linear-progress {
        margin-right: 0;
        border-radius: 0px 10px 10px 0px;
      }

      &.first-linear-progress.last-linear-progress {
        border-radius: 10px;
      }
    }
  }
}
