@import 'src/scss/core.module';

.container {
  height: 100%;
}

.month-row {
  color: $Blue;
  padding-left: 24px;
  font-size: 16px;
}

.expand-icon {
  color: $Black;
}

.active {
  transform: rotate(-180deg);
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.empty-state {
  color: $CrGray;
  text-align: center;
  margin-top: 32px;
  font-size: 19px;
}

.sub-row {
  height: 74px;
  display: flex;
  align-items: center;
  background-color: $ExpandedBackground;
  border-top: 1px solid $LightBlue;
  padding: 0 24px;
}
