@import 'src/scss/core.module.scss';

$HorizontalPadding: 36px;

.container {
  &.snackbar {
    z-index: 10;
    position: absolute;
    left: $HorizontalPadding;
    bottom: 24px;
    height: 68px;
    width: calc(100% - 72px);
  }

  .snackbar-content {
    height: 100%;
    width: 100%;
    padding-left: 22px;
    padding-right: 30px;
    background-color: $LightYellow;
    color: $Black;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }

  .action-button {
    margin-right: 16px;
  }
}
