@import 'src/scss/core.module.scss';

.login-form {
  display: flex;
  flex-direction: column;
  max-width: 360px;

  .link {
    font-weight: 600;
    font-size: 12px;
  }

  .checkbox {
    margin: 10px 0 34px;
  }

  .error {
    color: $RoseRed;
    font-size: 12px;
    display: none;

    &.visible {
      display: inline;
    }
  }
}


