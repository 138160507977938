@import 'src/scss/core.module.scss';

.tab {
  display: flex;
  border-top: 2px solid transparent;
  outline: none;
  padding-top: 18px;
  background: transparent;
  color: $White;
  opacity: 0.5;
  font-weight: bold;
  text-decoration: none;
  transition: $TransitionAll;
  letter-spacing: 0.5px;

  &:focus {
    border-top-color: $HoverBlue;
  }

  &.active {
    opacity: 1;
    border-top-color: $White;
    transition: $TransitionAll;
  }

  &:not(.active):hover {
    cursor: pointer;
    border-top: 2px solid $White;
    transition: $TransitionAll;
  }
}
