@import 'src/scss/core.module.scss';

.container {
  position: relative;
  height: 100%;
  min-width: 1300px;

  .my-tasks {
    padding: 30px 30px 0 60px;

    .header {
      margin-bottom: 26px;

      .title {
        font-weight: bold;
        font-size: 23px;
        line-height: 32px;
        margin: 0 8px 0 0;
      }
    }

    .chip {
      background-color: $LightBlue;
    }

    .dnd-banner {
      background-color: $LightYellow;
      padding: 0 8px 0 16px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    .table {
      height: calc(100% - 172px);
    }
  }
}
