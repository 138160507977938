.create-learner-form {
  display: flex;
  flex-direction: column;
  max-width: 480px;

  .footer {
    margin-top: 32px;
  }

  .banner {
    margin: 16px 0 8px;
  }
}



