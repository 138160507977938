@import 'src/scss/core.module.scss';

.tooltip {
  padding: 4px;
  border-radius: 2px;
  font-size: 12px;
  background-color: $LightBlue;
}

.tick {
  font-size: 12px;
  fill: $CrGray;
}

.chart {
  > :first-child {
    width: calc(100%)!important;

    > :first-child {
      width: calc(100%);
    }
  }
}
