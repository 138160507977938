@import 'src/scss/core.module.scss';

.select {
  background-color: $SoftGray;
  width: 100%;
}

.search {
  width: 100%
}

.icon {
  color: $SoftGray
}
