// Colors:
$Black: rgb(35, 31, 32);
$Blue: rgb(0, 92, 185);
$Background: rgb(243, 243, 243);
$LightBlue: rgb(221, 229, 242);
$White: rgb(255, 255, 255);
$HoverBlue: rgb(31, 143, 255);
$RoseRed: rgb(222, 27, 53);
$Yellow: rgb(255, 215, 15);
$Green: rgb(196, 211, 60);
$GreenCr: rgb(26, 181, 160);
$Pink: rgb(222, 27, 118);
$DarkPink: rgb(192, 57, 127);
$DarkCrGold: rgb(211, 123, 7);
$LightYellow: rgb(250, 238, 198);
$SoftGray: rgb(213, 213, 213);
$CrGray: rgb(146, 162, 170);
$DarkCrGray: rgb(37, 69, 85);
$LightBlueGray: rgb(246,248,251);
$LightBlueGraph: #BFDCF2;
$PurpleGraph: #D24BCB;
$OrangeGraph: #F46B1F;
$GreenGraph: #08A5A1;
$BlueReport: #E9F5FE;
$HoverBlue: rgb(31, 142, 255);
$DragHover: rgba(33, 43, 53, 0.1);
$ExpandedBackground: rgb(247, 248, 252);
$StepText: rgb(90, 144, 220);
$StepImage: rgb(146, 208, 72);
$StepAudio: rgb(246, 230, 82);
$StepVideo: rgb(205, 69, 134);

// Transitions:
$TransitionAll: all 0.2s ease-in-out;

:export {
  Black: $Black;
  Blue: $Blue;
  HoverBlue: $HoverBlue;
  LightBlue: $LightBlue;
  SoftGray: $SoftGray;
  RoseRed: $RoseRed;
  Yellow: $Yellow;
  Green: $Green;
  Background: $Background;
  DarkCrGold: $DarkCrGold;
  GreenCr: $GreenCr;
  PurpleGraph: $PurpleGraph;
  OrangeGraph: $OrangeGraph;
  GreenGraph: $GreenGraph;
  CrGray: $CrGray;
  StepText: $StepText;
  StepImage: $StepImage;
  StepAudio: $StepAudio;
  StepVideo: $StepVideo;
}
