@import 'src/scss/core.module.scss';

.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .table-container {
    width: 100%;
    height: calc(100% - 240px);
    overflow-y: scroll;
  }
}

.title {
  font-size: 18px;
  color: $Black;
  margin-bottom: 16px;
  margin-top: 0;
}
