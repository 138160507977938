@import 'src/scss/core.module';

.container {
  background-color: $LightBlue;
  color: $Black;
  max-width: 720px;
  padding: 16px 30px;
  margin: 40px 0 20px;

  .icon {
    font-size: 40px;
    color: $DarkCrGray;
  }

  .title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
}
