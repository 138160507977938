@import 'src/scss/core.module.scss';

$nav-header-height: 64px;

.navigation-header {
  box-sizing: border-box;
  width: 100%;
  height: $nav-header-height;
  display: flex;
  padding: 0 30px 10px 30px;
  align-items: flex-start;
  justify-content: space-between;
  background-color: $Blue;
  position: fixed;
  top: 0;
  z-index: 2;

  .tabs {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .tab {
      margin: 0 25px;
    }
  }

  .controls {
    margin-left: auto;
    display: flex;
  }

  .user-info {
    display: flex;
    align-items: center;
    padding-top: 10px;

    .name {
      font-weight: 600;
      font-size: 14px;
      color: $White;
      opacity: 0.75;
      margin-left: 10px;
    }

    .divider {
      width: 1px;
      height: 16px;
      background-color: $White;
      opacity: 0.25;
      display: inline-block;
      margin: 0 4px 0 10px;
    }

    .icon-down {
      color: $White;
      opacity: 0.5;
    }
  }

  .logo {
    padding-top: 10px;
  }
}

@media print {
  .navigation-header {
    display: none;
  }
}

