@import 'src/scss/core.module.scss';

.action {
  text-align: right;
  font-size: 16px;

  .button {
    margin-right: 10px;
  }
}
