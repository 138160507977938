@import 'src/scss/core.module';

.container {
  max-width: 1000px;
  width: 100%;

  .tabs {
    .tab {
      flex: 1;
      padding: 12px 40px;
      font-size: 16px;
      font-weight: 700;
      text-transform: inherit;
    }
  }

  .tab-panel {
    width: 100%;
    padding: 24px 0 0 0;
    height: 420px;
  }

  .footer {
    margin-top: 32px;
  }

  .expand-icon {
    color: $Black;

    &-active {
      transform: rotate(-180deg);
    }
  }
}

.tab-panel-root {
  padding: 24px 0;
}

.modal {
  width: calc(100% - 150px);
  max-width: 960px;
}


