@import 'src/scss/core.module.scss';

.details-information-header {
  margin: 20px 0;
  padding: 16px 32px;
  border-radius: 4px;
  background-color: $LightBlue;

  .title {
    color: $Black;
    font-weight: 700;
    font-size: 19px;
    margin: 0 0 8px 0;
  }

  .column-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .row {
    display: flex;
    align-items: center;

    .color-indicator {
      display: inline-block;
      height: 12px;
      width: 12px;
      border-radius: 50%;
    }

    .label {
      color: $Black;
      font-weight: 600;
      font-size: 12px;
      margin: 0 4px 0 8px;
      line-height: 20px;
    }

    .counter {
      color: $Black;
      display: inline-block;
      height: 20px;
      width: 20px;
      font-size: 12px;
      line-height: 20px;
      border-radius: 50%;
      text-align: center;
      font-weight: 700;
      background-color: $LightBlueGraph;
    }
  }

  .button-box {
    display: flex;
    justify-content: flex-end;
  }

  .learner-row {
    display: flex;
    flex-direction: column;

    .value {
      color: $Black;
      font-size: 27px;
      font-weight: 400;
    }

    .rows-container {
      display: flex;
    }

    .separator {
      margin: 0 5px;
    }
  }
}
