@import 'src/scss/core.module.scss';

.forgot-password-form {
  max-width: 360px;
  display: flex;
  flex-direction: column;

  .link {
    margin-top: 16px;
    font-size: 16px;
    font-weight: 600;
  }

  .button {
    margin-top: 40px;
  }
}
