.change-learner-password-form {
  display: flex;
  flex-direction: column;
  max-width: 430px;

  .footer {
    margin-top: 32px;
  }
}



