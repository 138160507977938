@import 'src/scss/core.module.scss';

.fab-link {
  position: fixed;
  bottom: 40px;
  right: 60px;
  z-index: 1;
  text-decoration: none;

  .fab {
    height: 48px;
    width: 48px;
    display: flex;
    justify-content: center;
    color: $White;
    text-transform: capitalize;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    background-color: $Blue;
    border-radius: 24px;
  }

  .add-icon-button {
    color: $White;
  }

  .add-icon {
    margin-right: 6px;
  }
}


.icon-menu-root {
  .icon-menu-paper {
    background-color: $Blue;
    color: $White;
  }
}
