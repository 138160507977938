@import 'src/scss/core.module.scss';

.task-card {
  padding: 10px 5px;
  background: $Background;
  border-radius: 2px;
  display: flex;
  align-items: center;

  .drag-icon {
    width: 16px;
    height: 16px;
    color: $CrGray;

    &:hover {
      cursor: move;
    }

    &.hidden {
      visibility: hidden;
    }
  }

  .image {
    max-width: 36px;
    margin: 0 8px 0 4px;
  }

  .title {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: $Black;
    margin: 0;
  }

  .preview-button {
    font-size: 12px;
    color: $DarkCrGray;

    &:hover, &:focus, &:active {
      color: $DarkCrGray;
      background-color: transparent;
    }
  }
}
