@import 'src/scss/core.module.scss';

.details-information {
  width: 100%;
  padding: 20px 60px;

  .additional-info {
    text-align: right;

      .date-title {
        color: $DarkCrGray;
        font-size: 14px;
        font-weight: 400;
      }

      .date {
        font-weight: 600;
        margin-right: 18px;
      }

      .chip {
        background-color: $White;
      }
  }

  .header {
    margin: 20px 0;
    padding: 16px 32px;
    border-radius: 4px;
    background-color: $LightBlue;

    .title {
      color: $Black;
      font-weight: 700;
      font-size: 19px;
      margin: 0 0 8px 0;
    }

    .column-container {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }

    .member-row {
      display: flex;
      align-items: center;

      .color-indicator {
        display: inline-block;
        height: 12px;
        width: 12px;
        border-radius: 50%;
      }

      .label {
        color: $Black;
        font-weight: 600;
        font-size: 12px;
        margin: 0 4px 0 8px;
        line-height: 20px;
      }

      .counter {
        color: $Black;
        display: inline-block;
        height: 20px;
        width: 20px;
        font-size: 12px;
        line-height: 20px;
        border-radius: 50%;
        text-align: center;
        font-weight: 700;
        background-color: $LightBlueGraph;
      }
    }
  }

  .title-container {
    display: flex;
    align-items: center;

    .title {
      font-weight: bold;
      font-size: 23px;
      color: $Black;
      margin-right: 32px;
    }
  }

  .image {
    height: 64px;
  }

  .admin-span {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .info-value {
    color: $Black;
    font-weight: 600;
  }

  .admin-row {
    border: transparent;
  }

  .email {
    color: $Blue;
    font-weight: bold;
    margin-left: 16px;
  }
}
