@import 'src/scss/core.module.scss';

.tasks-amount {
  font-weight: 600;
  font-size: 12px;
  color: $DarkCrGray;
}

.preview-button-wrap {
  .preview-button {
    font-size: 12px;
    color: $DarkCrGray;

    &:hover,
    &:focus,
    &:active {
      color: $DarkCrGray;
      background-color: transparent;
    }
  }
}
