@import 'src/scss/core.module';

.image {
  height: 64px;
}

.info-value {
  color: $Black;
  font-weight: 600;
}

.password-row {
  border: transparent;
}

.email {
  color: $Blue;
  font-weight: bold;
  width: 100%;
}

.switch {
  white-space: nowrap;
  padding-right: 10px;
}

.chip-row {
  .chip {
    font-weight: 600;

    &.primary {
      background-color: $LightBlue;
    }
  }
}
