@import 'src/scss/core.module.scss';

.step-card {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 8px;
  padding: 8px 6px 8px 0;
  border-bottom: 1px solid $Background;

  &.dragging {
    background-color: $White;
    box-shadow: 0 2px 8px $DragHover, 0 8px 16px $DragHover;
    border-radius: 2px;
  }

  .drag-icon {
    color: $CrGray;
  }

  .order-number {
    margin: 0 12px;
    font-weight: 600;
    font-size: 14px;
    color: $DarkCrGray;
  }

  .image {
    height: 68px;
    width: 68px;
    border-radius: 2px;
  }

  .icon {
    height: 68px;
    width: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    color: $CrGray;
    background-color: $Background;
  }

  .step-details {
    margin: 0 16px;
    font-size: 12px;
    color: $Black;
    font-weight: 600;

    .title {
      font-weight: 700;
      font-size: 14px;
      margin: 0 0 6px 0;
    }

    .details-box {
      display: flex;

      >:first-child {
        margin-right: 32px;
      }

      .details-column {
        display: flex;
        flex-direction: column;

        .details-title {
          color: $DarkCrGray;
          font-weight: normal;
        }
      }
    }
  }

  .actions {
    margin-left: auto;

    .button {
      margin-right: 8px;
    }
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.hidden {
  visibility: hidden;
}
