.repertoire-form {
  display: flex;
  flex-direction: column;
  max-width: 480px;

  .footer {
    margin-top: 32px;
  }

  .input {
    margin: 5px 0 0;
  }
}
