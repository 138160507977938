@import 'src/scss/core.module';

.user-details {
  width: 100%;
  padding: 20px 60px;
  height: calc(100% - 40px);

  .additional-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 38px;

      .date-title {
        color: $DarkCrGray;
        font-size: 14px;
        font-weight: 400;
        line-height: 30px;
        display: inline-block;
      }

      .date {
        font-weight: 600;
        margin-right: 18px;
      }

    .breadcrumbs {
      font-size: 14px;
      font-weight: 600;
      line-height: 26px;
    }
  }

  .tabs {
    margin: 0 -40px 14px;

    .tab {
      padding: 12px 24px;
      font-size: 19px;
      font-weight: 700;
      text-transform: none;

      &.hidden {
        display: none;
      }
    }
  }

  .tab-panel {
    padding-left: 0;
    padding-right: 10px;
    height: calc(100% - 160px);
  }

  .chip {
    font-weight: 600;
    background-color: $LightBlue;
  }
}
