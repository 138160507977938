@import 'src/scss/core.module.scss';

.empty-state {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 424px;
  }

  .icon-container {
    width: 96px;
    height: 96px;
    border-radius: 50%;
    background: $Background;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon {
    width: 36px;
    height: 36px;

    path {
      fill: $DarkCrGray;
    }
  }

  .title {
    color: $DarkCrGray;
    text-align: center;
    margin-top: 32px;
    font-size: 19px;
  }
}

