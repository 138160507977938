@import 'src/scss/core.module.scss';

$RowHeight: 76px;
$HeaderHeight: 40px;

.table {
  border-spacing: 0;
  width: 100%;

  .tr {
    height: $RowHeight;
    display: flex;
    align-items: center;
    border-top: 1px solid $Background;
  }

  .header {
    height: $HeaderHeight;
    border-top: none;
  }

  .th,
  .td {
    margin-right: 26px;
    color: $DarkCrGray;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .expanded {
    background-color: $ExpandedBackground;
  }
}
