.edit-details-form {
  display: flex;
  flex-direction: column;

  .footer {
    margin-top: 32px;
  }

  .select {
    margin: 16px 0 8px;
  }
}



