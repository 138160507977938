@import 'src/scss/core.module.scss';

.association-row {
  display: flex;
  padding: 16px 0;
  align-items: center;
  border-bottom: 1px solid #F3F3F3;

  .nav-link {
    text-decoration: none;
  }

  .label {
    color: $Black;
    font-size: 14px;
    font-weight: bold;
    margin: 0 0 0 8px;

    &:hover {
      color: $HoverBlue;
    }
  }

  .avatar {
    width: 36px;
    height: 36px;
  }
}
