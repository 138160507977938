@import 'src/scss/core.module.scss';

.login-page {
  height: 100%;

  .banner {
    background: $DarkPink;
    padding-left: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
      margin-top: 0;
      margin-bottom: 40px;
      font-size: 57px;
      font-weight: normal;
      color: $White;
      max-width: 484px;
    }

    .subtitle {
      color: $White;
    }
  }

  .content {
    padding: 48px 90px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .login-title {
      font-size: 27px;
      font-weight: bold;
      color: $Black;
      max-width: 450px;
      margin-bottom: 30px;
    }

    .logo {
      max-width: 150px;
    }

    .info {
      max-width: 510px;
    }
  }
}


