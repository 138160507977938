@import 'src/scss/core.module.scss';

.info-message {
  font-size: 12px;

  &.error {
    color: $RoseRed;
  }

  &.success {
    color: $GreenCr;
  }
}
