@import 'src/scss/core.module.scss';

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  background-color: $White;
  border: 1px solid $SoftGray;
  width: 95%;
  height: 95%;

  .content {
    display: flex;
    height: calc(100% - 100px);
  }
}
