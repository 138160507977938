@import 'src/scss/core.module';

.report-step-card {
  .title-container {
    display: flex;
    margin-bottom: 10px;

    .title {
      font-size: 14px;
      color: $CrGray;
      margin-left: 8px;
    }

    .content-icon {
      width: 20px;
      height: 20px;
      color: $CrGray;
    }
  }

  .image {
    width: 150px;
    height: 150px;
    border-radius: 2px;
  }

  .icon {
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    color: $CrGray;
    background-color: $BlueReport;
  }
}
